import { FC, MouseEvent, SyntheticEvent } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import clsx from 'clsx';
import { A11y, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuid } from 'uuid';

import { DiscountIcon, PostcardModal } from '@components';
import { FavoriteIcon } from '@components/product/FavoriteIcon/FavoriteIcon';
import { HighlightIcon } from '@components/product/highlight-icon/HighlightIcon';
import { InfoSection } from '@components/product/info-section/InfoSection';
import { ProductPrice } from '@components/shared/product-price/ProductPrice';
import { ProductStockStatus } from '@components/shared/product-stock-status/ProductStockStatus';
import { useCartData } from '@contexts/cart-data';
import { ICountriesMap } from '@lib/countriesMap';
import { GAMTrackService } from '@services';
import {
  attributesToFilter,
  IAttribute,
  ICartItem,
  IProduct,
  IProductAttributesDictionary,
  IProductOptions,
  IUser
} from '@types';
import { sortAttributesByOrder } from '@utils/formatting';
import { IconButton } from '../../../shared';

import classes from './HighlightProductCard.module.scss';

interface IProps {
  item: IProduct;
  withoutFavorite?: boolean;
  isSelected?: boolean;
  profile?: IUser | null;
  containerClassName?: string;
  index?: number;
  onCardClick?: (productId: string, index: number) => void;
  highlightTitle?: string;
}

const HighlightProductCard: FC<IProps> = ({ onCardClick, index, item, withoutFavorite, highlightTitle }) => {
  const router = useRouter();
  const { cart, setCart } = useCartData();
  const isInCart = cart && cart.length ? Boolean(cart.find((i) => i.product.id === item.id)) : false;

  const productParams: IAttribute[] = [];
  (Object.keys(item.attributes) as IProductAttributesDictionary[])
    .sort(sortAttributesByOrder(item.attributes))
    .forEach((attributeKey) => {
      const attribute = item.attributes[attributeKey as IProductAttributesDictionary];
      if (attribute?.value) {
        productParams.push(attribute);
      }
    });

  const cardClickHandler = (ev: SyntheticEvent | MouseEvent) => {
    ev.stopPropagation();
    if (onCardClick) {
      onCardClick(item.id, index || 0);
    }
  };

  const addToCartHandler = (ev: SyntheticEvent | MouseEvent, productOptions?: IProductOptions) => {
    ev.stopPropagation();
    ev.preventDefault();
    if (item.inStockStatus === 'soldout') return;

    if (onCardClick) {
      onCardClick(item.id, index || 0);
    }
    if (!isInCart) {
      const payload: ICartItem = {
        product: item,
        qty: 1,
        productId: item.id,
        id: uuid(),
        ...(productOptions ? { productOptions } : {})
      };
      setCart((c) => [...c, payload]);
      GAMTrackService.trackAddToCart(payload);
    } else {
      router.push('/tp/cart');
    }
  };

  const submitPostcardMessage = (e: SyntheticEvent | MouseEvent, message?: string) => {
    addToCartHandler(e, { postcardText: message });
  };

  // cut to 3 items for now
  const otherAttrs = productParams.filter(({ code }) => !attributesToFilter.includes(code)).splice(0, 3);
  const countryAttr = item.attributes.country as IAttribute<ICountriesMap>;

  return (
    <article className={classes.cardContainer}>
      <Link href={`/${item.seoKey}`} className={classes.cardLink} onClick={cardClickHandler}>
        <div className={classes.imageContainer}>
          <HighlightIcon label={highlightTitle} classNameWrapper={classes.highlightIcon}/>
          {item.images?.length > 1 ? (
            <Swiper
              loop={false}
              slidesPerView={1}
              spaceBetween={50}
              speed={500}
              modules={[Navigation, Pagination, A11y]}
              pagination={{ el: '.pagination', clickable: false }}
              className={classes.swiper}
            >
              {item.images.map((image, index) => (
                <SwiperSlide key={index}>
                  <Image
                    src={image.preview || '/assets/images/flower-no-image.svg'}
                    className={classes.image}
                    alt={`${item.alias}-${index}`}
                    quality={100}
                    fill
                  />
                </SwiperSlide>
              ))}
              <div
                className={clsx('pagination', classes.customPagination)}
                style={{
                  margin: '0 auto',
                  zIndex: 2,
                  position: 'absolute',
                  height: '10px',
                  color: '#691B33',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              ></div>
            </Swiper>
          ) : (
            <Image
              className={classes.image}
              src={item.images[0]?.preview || '/assets/images/flower-no-image.svg'}
              alt={item.displayName}
              quality={100}
              fill
            />
          )}
          {!withoutFavorite && item.inStockStatus !== 'soldout' ? (
            <FavoriteIcon productId={item.id} containerClassName={classes.favoriteIcon} />
          ) : null}
        </div>
        <div className={classes.infoContainer}>
          <ProductStockStatus status={item.inStockStatus} />
          <InfoSection name={item.displayName} attributes={otherAttrs} country={countryAttr?.value} />
          <div className={classes.cardFooter}>
            <ProductPrice price={item.price} specialPrice={item.specialPrice} className={classes.cardFooterPrice} />
            {item.inStockStatus !== 'soldout' ? (
              <>
                {item.catalogCategoryProductsGroup === 'postcards' ? (
                  <PostcardModal
                    onSubmit={submitPostcardMessage}
                    preventOpenModal={isInCart}
                    labelPostcard={item.displayName}
                  >
                    <IconButton
                      className={clsx(classes.buyButton, { [classes.buyButtonInCart]: isInCart })}
                      ariaLabel="Додати до кошику"
                      icon={isInCart ? 'cartChecked' : 'cartFilled'}
                    />
                  </PostcardModal>
                ) : (
                  <IconButton
                    className={clsx(classes.buyButton, { [classes.buyButtonInCart]: isInCart })}
                    onClick={addToCartHandler}
                    ariaLabel="Додати до кошику"
                    icon={isInCart ? 'cartChecked' : 'cartFilled'}
                  />
                )}
              </>
            ) : null}
          </div>
        </div>
        <DiscountIcon price={item.price} specialPrice={item.specialPrice} />
      </Link>
    </article>
  );
};

export { HighlightProductCard };
